import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReloadLogo from '../../../../assets/images/afrokavair.svg';
import Dots from '../../../../assets/images/svg/dots.svg';
import GetStartedMailOutlined from '../../../../assets/images/svg/GetStartedMailOutlined';
import MagicWandIconOutlined from '../../../../assets/images/svg/MagicWandIconOutlined';
import AfrokaviarRadio from '../../../../assets/images/afrokaviarRadio.png';
import MobileScreenPhoto from '../../../../assets/images/mobile-screen-photo.png';

// info icons
import AccessIcon from '../../../../assets/images/info-icons/access-icon.png';
import AvatarIcon from '../../../../assets/images/info-icons/avatar-icon.png';
import BookmarkIcon from '../../../../assets/images/info-icons/bookmark-icon.png';
import NewsIcon from '../../../../assets/images/info-icons/news-icon.png';
import ShareIcon from '../../../../assets/images/info-icons/share-icon.png';
import UploadIcon from '../../../../assets/images/info-icons/upload-icon.png';

function MagicLinkForm({
    sendMagicLink,
    emailAddress,
    updateEmailAddress,
    magicLinkSentStatus,
    emailAddressIsValid,
}) {
    const [getStartedForm] = Form.useForm();
    const { t } = useTranslation();
    const getStartedEmailInput = useRef();

    const loadingIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

    return (
        <Form
            className="login-form-magic"
            form={getStartedForm}
            onSubmit={e => sendMagicLink(e)}
        >
            <Form.Item
                name="email"
                className="form-input"
                rules={[
                    {
                        required: true,
                        message: t('getStarted.loginForm.email.message'),
                    },
                ]}
            >
                <Input
                    ref={getStartedEmailInput}
                    value={emailAddress}
                    prefix={<GetStartedMailOutlined />}
                    name="email"
                    placeholder={t('getStarted.loginForm.email.placeholder')}
                    onChange={e => updateEmailAddress(e)}
                    disabled={magicLinkSentStatus === 'sending'}
                />
            </Form.Item>
            <Form.Item style={{ marginBottom: '0' }}>
                <Button
                    className="ant-btn-squared ant-btn-icon ant-btn-icon--left ant-btn-fluid magic-submit-button"
                    type="primary"
                    htmlType="submit"
                    icon={
                        magicLinkSentStatus === 'sending' ? (
                            <Spin indicator={loadingIcon} />
                        ) : (
                            <MagicWandIconOutlined />
                        )
                    }
                    onClick={sendMagicLink}
                    disabled={
                        !emailAddress ||
                        !emailAddressIsValid ||
                        magicLinkSentStatus === 'sending'
                    }
                >
                    {magicLinkSentStatus === 'sending'
                        ? t('getStarted.loginForm.magicLinkButton.active')
                        : t('getStarted.loginForm.magicLinkButton.default')}
                </Button>
            </Form.Item>
        </Form>
    );
}

function Login({
    sendMagicLink,
    emailAddress,
    updateEmailAddress,
    magicLinkSentStatus,
    emailAddressIsValid,
    setTermsAndConditionsModalVisible,
    setPrivacyPolicyModalVisible,
}) {
    const { t } = useTranslation();
    const [getStartedForm] = Form.useForm();
    const getStartedEmailInput = useRef();

    const loadingIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

    return (
        <div>
            <div className="visit-info-card">
                <div className="info-text-container">
                    <li>
                        <h2>Visit</h2>
                        <a href="https://afrokaviarmusic.com/" target="_blank">
                            Afrokaviarmusic.com
                        </a>
                    </li>

                    <img src={AfrokaviarRadio} height={40} width={200} alt="" />
                </div>
            </div>
            <div className="login-parent">
                <div className="logo-container">
                    <img
                        className="reload-logo"
                        src={ReloadLogo}
                        alt="reload-logo"
                    />

                    <img
                        width={500}
                        height={200}
                        className="mobile-screen"
                        src={MobileScreenPhoto}
                    />
                </div>
                <Row className="login-row">
                    {/* Left column */}
                    <Col className="login-col-left" xs={24} lg={10}>
                        <h1>Celebrate afro unity, diversity and growth</h1>
                        <p>
                            Afrokaviar is a social sharing platform where
                            everyone can contribute knowledge and share
                            information.
                        </p>
                    </Col>
                    {/* Right column */}
                    <Col className="login-col-right" xs={24} lg={14}>
                        <img className="dots" src={Dots}></img>
                        <div className="login-form-box">
                            <div className="form-head">
                                <span className="form-title">
                                    LOGIN &amp; SIGNUP
                                </span>
                                <span className="form-subtitle">
                                    New accounts will be created automatically
                                    for new users!
                                </span>
                            </div>

                            <Form className="form-input-container">
                                <Form.Item
                                    name="email"
                                    className="email-input"
                                    rules={[
                                        {
                                            required: true,
                                            message: t(
                                                'getStarted.loginForm.email.message'
                                            ),
                                        },
                                    ]}
                                >
                                    <Input
                                        ref={getStartedEmailInput}
                                        value={emailAddress}
                                        prefix={<GetStartedMailOutlined />}
                                        name="email"
                                        placeholder={t(
                                            'getStarted.loginForm.email.placeholder'
                                        )}
                                        onChange={e => updateEmailAddress(e)}
                                        disabled={
                                            magicLinkSentStatus === 'sending'
                                        }
                                    />
                                </Form.Item>

                                <Form.Item style={{ marginBottom: '0' }}>
                                    <Button
                                        className="ant-btn-squared ant-btn-icon ant-btn-icon--left ant-btn-fluid magic-submit-button"
                                        type="primary"
                                        htmlType="submit"
                                        icon={
                                            magicLinkSentStatus ===
                                            'sending' ? (
                                                <Spin indicator={loadingIcon} />
                                            ) : (
                                                <MagicWandIconOutlined />
                                            )
                                        }
                                        onClick={sendMagicLink}
                                        disabled={
                                            !emailAddress ||
                                            !emailAddressIsValid ||
                                            magicLinkSentStatus === 'sending'
                                        }
                                    >
                                        {magicLinkSentStatus === 'sending'
                                            ? t(
                                                  'getStarted.loginForm.magicLinkButton.active'
                                              )
                                            : t(
                                                  'getStarted.loginForm.magicLinkButton.default'
                                              )}
                                    </Button>
                                </Form.Item>
                            </Form>

                            <div className="terms-text-container">
                                <p>
                                    {t('getStarted.loginForm.agreement.text')}{' '}
                                    &nbsp;
                                    <span
                                        onClick={() =>
                                            setTermsAndConditionsModalVisible(
                                                true
                                            )
                                        }
                                    >
                                        {t(
                                            'getStarted.loginForm.agreement.terms'
                                        )}
                                    </span>
                                    &nbsp;&amp;&nbsp;
                                    <span
                                        onClick={() =>
                                            setPrivacyPolicyModalVisible(true)
                                        }
                                    >
                                        {t(
                                            'getStarted.loginForm.agreement.policy'
                                        )}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* Info cards */}
                <Row className="info-row">
                    <Col className="info-col-left" xs={24} lg={8}>
                        <div className="info-card">
                            <h2>UPLOAD</h2>
                            <div className="info-text-container">
                                <li>
                                    <img src={UploadIcon} alt="" />
                                    <p>
                                        Upload and share to the Afrokaviar
                                        community or just archive content
                                    </p>
                                </li>
                                <li>
                                    <img src={AccessIcon} alt="" />
                                    <p>
                                        Always access the content you upload as
                                        it is stored on a Blockchain
                                    </p>
                                </li>
                            </div>
                        </div>
                    </Col>

                    <Col className="info-col-left" xs={24} lg={8}>
                        <div className="info-card">
                            <h2>SHARE</h2>
                            <div className="info-text-container">
                                <li>
                                    <img src={AvatarIcon} alt="" />
                                    <p>
                                        Post content under your avatar or
                                        anonymously through the Afrokaviar
                                        avatar
                                    </p>
                                </li>
                                <li>
                                    <img src={ShareIcon} alt="" />
                                    <p>
                                        Grab and share links to the content you
                                        posted or that others have posted
                                    </p>
                                </li>
                            </div>
                        </div>
                    </Col>

                    <Col className="info-col-left" xs={24} lg={8}>
                        <div className="info-card">
                            <h2>TRACK</h2>
                            <div className="info-text-container">
                                <li>
                                    <img src={NewsIcon} alt="" />
                                    <p>
                                        Keep up with hot-news as you discover
                                        what others are sharing on Afrokaviar
                                    </p>
                                </li>
                                <li>
                                    <img src={BookmarkIcon} alt="" />
                                    <p>
                                        Bookmark stories shared in Afrokaviar so
                                        that you can easily retrieve them{' '}
                                    </p>
                                </li>
                            </div>
                        </div>
                    </Col>
                </Row>

                {/* Footer */}
                <div className="login-footer">
                    <div className="login-footer-bottom">
                        <div>
                            &copy; Copyright {new Date().getFullYear()}{' '}
                            Afrokaviar Inc. All rights reserved.
                        </div>
                        <div>
                            <a href="#">v1.0.0</a>
                        </div>
                        <div>
                            <a href="#">About</a>
                            <a
                                href="https://www.iubenda.com/privacy-policy/76327275"
                                class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
                                title="Privacy Policy "
                            >
                                Privacy Policy
                            </a>
                            <a
                                href="https://www.iubenda.com/terms-and-conditions/76327275"
                                class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
                                title="Terms and Conditions "
                            >
                                Terms &amp; Conditions
                            </a>
                            <a
                                href="https://www.iubenda.com/privacy-policy/76327275/cookie-policy"
                                class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe "
                                title="Cookie Policy "
                            >
                                Cookie Policy
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
